@import '../../styles/base';

.sign-in-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background: linear-gradient($color-dark-purple 40%, $color-almost-white 40%);

  .input-title {
    font-family: 'Roboto Mono', monospace;
    margin-top: 15px;
    font-weight: 500;
  }

  .logo {
    width: 150px;
    height: 60px;
    margin-bottom: 110px;
    margin-top: 70px;
    filter: invert(1);
  }

  .sign-in-form {
    padding: 1.5rem;
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $color-white;
    border-radius: 5px;
    margin-top: -60px;

    .form-title {
      font-size: 33px;
      font-weight: 600;
      color: #111928;
    }

    .sign {
      margin-top: 10px;
    }

    .email-password {
      width: 100%;

      .remember-me {
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: #4B5563;
        font-size: 16px;

        .check-box {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          background-color: #F9FAFB;
        }
      }
    }

    .reset-new-account {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .create-account {
        margin-top: 10px;
        color: #4B5563;

        .register {
          margin-left: 0.4rem;
        }
      }
    }
  }
}

