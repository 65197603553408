@import "../../styles/base";

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Grey overlay */
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Transition only opacity */
  pointer-events: none;
  z-index: 1000;
}

.modal-overlay.open {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: 1;
  pointer-events: auto;
}

.modal-content {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100%;
  max-width: 786px;
  height: 69vh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-top: auto;
  transform: translateY(100%);
  transition: transform 1s ease-in-out;
  z-index: 1001;
  background-color: $color-lighter-grey;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  .modal-header {
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    .modal-header-title {
      font-size: 1.25rem;
      margin-left: 1.25rem;
      font-weight: bold;
    }
    .modal-close-button {
      margin-right: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
      filter: invert(70%);
      cursor: pointer;
    }
  }
  .modal-form {
    margin-top: 1.5rem;
    padding: 1.5rem 0.8rem;
    height: 80%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $color-white;
    border-radius: 5px;
    .form-title {
      font-size: 33px;
      font-weight: 600;
      color: #111928;
    }
    .form-subtitle {
      color: grey;
      margin-bottom: 0.5rem;
    }
  }
}

.modal-overlay.open .modal-content {
  transform: translateY(0);
}
