@import '../../styles/base';

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .success-step {
    color: $color-light-blue;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .success-title {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .success-subtitle {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: grey;
    margin-bottom: 2rem;
    width: 80%;
  }
  .success-checkmark {
    width: 5rem;
    height: 5rem;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }
}