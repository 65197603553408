@import "../../styles/base";

.app-header {
  height: 5vh;
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  max-width: 786px;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  z-index: 999;
  .biblio-logo {
    width: 85px;
    height: 85px;
    margin-left: 4%;
    cursor: pointer;
  }
  .right-part {
    display: flex;
    justify-content: flex-end;
    width: 80px;
    margin-right: 4%;
    .header-logo {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}
