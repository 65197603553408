@import "../../styles/base";

.qualification-card {
  margin-bottom: 25px;

  .qualification-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 36rem;
    height: 5rem;
    padding: 0 1rem;
    background-color: white;
    border: 1px solid #D1D5DB;
    border-radius: 0.5rem;
    border-bottom: none;
    margin-bottom: -1px;

    .qualification-left {
      display: flex;
      align-items: center;

      .q-main {
        margin-left: 1rem;
      }
    }

    .three-dots-settings {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }
  }

  .dottedSeparator {
    border-top: 2px dashed #D1D5DB !important;
    height: 0px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #FFFFFF;
    z-index: 1;
    position: relative;
  }

  .qualification-second-block {
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.5rem;
    width: 36rem;
    border: 1px solid #D1D5DB;
    padding: 1rem;
    border-top: none;

    .qualification-module {
      margin-bottom: 1rem;
    }

    .dotted {
      border-top: 2px dashed #D1D5DB !important;
      border: none;
    }

    .qualification-date {
      display: flex;
      justify-content: space-between;
    }
  }

  .manual-handling {
    height: 45vh;

    .actions-buttons {
      width: 80%;
      margin-top: 3rem;

      .edit {
        border: 1px solid blue;
        margin-bottom: 1rem;
      }

      .archive {
        border: 1px solid blue;
        color: blue;
        background-color: white;
        margin-bottom: 1rem;
      }

      .delete {
        border: 1px solid red;
        color: red;
        background-color: white;
      }
    }
  }
}

@include phone-screen {
  .qualification-card {
    .qualification-block {
      width: 19rem;
      height: 4.8rem;
    }

    .qualification-second-block {
      width: 19rem;
    }
  }
}

.qualification {
  display: flex;
  flex-direction: column;

  .qualification-title {
    font-family: 'Roboto Mono', monospace;
    color: grey;
    font-size: 14px;
    font-weight: 500;

  }

  .qualification-type {
    font-weight: 600;
    color: black;
    font-size: 18px;
  }
}

.verified-check {
  width: 1.75rem;
  height: 1.75rem;
}

.module-icon {
  width: 2rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 50%;
  margin-right: 1rem;
}

.module-data {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
