.manual-handling {
  height: 20vh;
  background-color: white;
  border: 1px solid #D1D5DB;

  .modal-header {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D1D5DB
  }

  .actions-buttons {
    width: 80%;
    margin-top: 3rem;

    .edit {
      border: 1px solid #0F52FF;
      margin-bottom: 1rem;
    }

    .archive {
      border: 1px solid #0F52FF;
      color: #0F52FF;
      background-color: white;
      margin-bottom: 1rem;
    }

    .delete {
      border: 1px solid red;
      color: red;
      background-color: white;
    }
  }
}
