@import '../../styles/base';

.reset-pass-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background: linear-gradient($color-dark-purple 40%, $color-almost-white 40%);

  .input-title {
    font-family: 'Roboto Mono', monospace;
    margin-top: 15px;
    font-weight: 500;
  }

  .logo {
    width: 150px;
    height: 60px;
    margin-bottom: 110px;
    margin-top: 70px;
    filter: invert(1);
  }

  .reset-pass-form {
    padding: 1.5rem;
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $color-white;
    border-radius: 5px;
    margin-top: -60px;

    .form-title {
      font-size: 33px;
      font-weight: 600;
      color: #111928;
    }

    .reset-pass {
      margin-top: 10px;
    }

    .back {
      display: block;
      text-align: center;
    }
    
    .reset-pass-buttons {
      align-self: stretch;
    }
  }
}

