@font-face {
  font-family: 'Halyard Display';
  src: local('Halyard Display'), url('./fonts/HalyardDisplay.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
body {
  margin: 0;
  font-family: 'Halyard Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Halyard Display', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F3F4F8;
}
