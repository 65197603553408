@import "../../styles/base";

.navbar {
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
  max-width: 786px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  border-top: 1px solid #D1D5DB;
  .navbar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    margin-left: 4%;
    margin-right: 4%;

    .navbar-logo-container {
      width: 65px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      margin-bottom: 3px;
      background-color: white;
      cursor: pointer;

      .navbar-logo {
        width: 28px;
        height: 28px;
      }
    }

    .navbar-item-title {
      text-decoration: none;
      color: #6B7280;
      font-size: 12px;
    }

    &.active {
      background-color: white;
      .navbar-logo-container {
        background-color: $color-light-blue;
        .navbar-logo {
          filter: brightness(0) invert(1) grayscale(1);
        }
      }
      .navbar-item-title {
        color: $color-light-blue;
      }
    }
  }
}
