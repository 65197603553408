@import "../../styles/base";

.add-certificate {
  display: flex;
  flex-direction: column;
  width: 100%;

  .add-certificate-step {
    color: $color-light-blue;
    font-size: 1.2rem;
    font-family: 'Roboto Mono', monospace;
    margin-bottom: 1rem;
  }

  .add-certificate-title {
    font-size: 2rem;
    font-weight: bold;
  }

  .add-certificate-subtitle {
    margin-top: 1rem;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto, 'Halyard Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
    color: #6B7280;
    margin-bottom: 2rem;
    text-align: center;
  }

  .add-certificate-buttons {
    display: flex;
    width: 100%;

    .upload-picture {
      display: flex;
      background-color: white;
      color: $color-light-blue;
      font-weight: 600;
      font-size: 16px;
      border: 1px solid $color-light-blue;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 10px;
      transition: background-color 0.3s;
      margin-right: 0.5rem;
      align-items: center;
      justify-content: center;


      .upload-icon {
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
      }
    }

    .take-photo {
      height: 50.8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;

      .take-photo-icon {
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.modal-overlay-camera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content-camera {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
}

.capture-button {
  background-color: #007bff;
  color: white;
  border: 3px solid blue;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  margin-top: 0.5rem;
  z-index: 2001;
}

.close-button {
  position: absolute;
  top: 2rem;
  left: 2rem;
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 2001;
}

.react-webcam {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

#upload-input {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}
