@import "../../styles/base";

.homepage {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient($color-dark-purple 35%, $color-almost-white 35%);

  .greetings {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 75%;
    font-size: 33px;
    font-weight: bold;
    color: white;
  }

  .your-qualifications {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 3rem;
    width: 75%;

    .qualifications-subtitle {
      font-size: 16px;
      font-weight: 400;
      margin-left: 0.5rem;
      color: white;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 36rem;
    height: 15rem;
    padding: 0 1rem;
    background-color: white;
    border: 1px solid darkgrey;
    border-radius: 0.5rem;

    .empty-state-text {
      margin-top: 1rem;

      .empty-state-title {
        font-family: 'Roboto Mono', monospace;
        font-size: 14px;
        font-weight: 500;
      }
      .empty-state-subtitle{
        font-size: 18px;
        font-weight: 600;
      }
    }

    .add-qualification {
      border: 1px solid #0F52FF;
      color: #0F52FF;
      background-color: white;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}

@include phone-screen {
  .homepage {
    width: 100%;
    height: 85vh;
    background: linear-gradient($color-dark-purple 33%, $color-almost-white 33%);

    .greetings {
      margin-top: 2rem;
    }

    .empty-state {
      width: 19rem;
    }
  }
}

.verified-check {
  width: 1.75rem;
  height: 1.75rem;
}
