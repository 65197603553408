@import '../../styles/base';

.certificate-handling {
  display: flex;
  flex-direction: column;
  width: 100%;

  .certificate-handling-step {
    color: $color-light-blue;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .certificate-handling-title {
    font-size: 2rem;
    font-weight: bold;
  }

  .certificate-handling-subtitle {
    margin-top: 1rem;
    font-size: 1rem;
    color: grey;
    margin-bottom: 2rem;
    text-align: center;
  }

  .certificate-handling-box {
    padding: 1.5rem 1rem;
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $color-white;
    border-radius: 5px;

    .certificate-handling-header {
      font-size: 1.25rem;
      font-weight: bold;
    }

    .certificate-name-input {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .file-details {
      display: flex;
      flex-direction: row;
      background-color: $color-lighter-grey;
      border: 1px solid lightgrey;
      border-radius: 3px;
      width: 99%;
      justify-content: space-between;
      align-items: center;

      .img-file-description {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        .certificate-image {
          margin-right: 1rem;
          padding: 5px;
          border-radius: 20px;
          background-color: #F3F4F8;
          padding-bottom: 0;

          img {
            width: 1.5rem;
          }
        }

        .file-name-size {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .certificate-name {
            color: black;
          }

          .certificate-size {
            color: grey;
          }
        }
      }

      .checkmark-img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }

    .delete-file {
      color: red;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .certificate-handling-buttons {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    align-items: center;

    .certificate-previous {
      margin-top: 0.5rem;
      text-decoration: underline;
      color: black;
      cursor: pointer;
    }
  }

  .modal-confirm {
    background-color: white;
    color: blue;
    border: 1px solid blue;
    margin-top: 1rem;

    &:hover {
      background-color: lighten($color-light-blue, 75%);
    }
  }
}
