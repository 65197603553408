@import "../../styles/base";

.forgot-password-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  background: linear-gradient($color-dark-purple 40%, $color-almost-white 40%);
  .logo {
    width: 150px;
    height: 60px;
    margin-bottom: 110px;
    margin-top: 70px;
    filter: invert(1);
  }
  .forgot-password-form {
    padding: 1.5rem;
    width:75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $color-white;
    border-radius: 5px;
    margin-top: -60px;
    .form-title {
      font-size: 33px;
      font-weight: 600;
      color: #111928;
    }
    .forgot {
      margin-top: 10px;
    }
    .reset-password {
      width: 100%;
      .first-form-subtitle {
        font-size: 16px;
        color: grey;
        margin-bottom: $size-small;
      }
    }
    .reset-new-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .return-log-in {
        color: blue;
        margin-top: 5px;
      }
    }
  }
}
